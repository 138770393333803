<template>
<div id="app">
    <Navbar />
    <router-view />
    <Fondo1 />
    <!-- <fab position="position" bg-color="#007EFF" actions="fabActions" @cache="cache" @alertMe="alert">
    </fab> -->
    <!-- Código de instalación Cliengo para marketing@grupoacto.com -->
    <Footer />

</div>
</template>

<script>
/* import fab from 'vue-fab' */
const $ = require('jquery')
import i18n from '@/plugins/i18n';
window.$ = $


import Fondo1 from "@/components/Fondo1.vue"
import Navbar from "@/components/Navbar.vue"
import Footer from "@/components/Footer.vue"

export default {
    name: 'App',
    components: {
        Fondo1,
        Navbar,
        Footer
        /* fab */

    },

    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Home',
      // all titles will be injected into this template
      titleTemplate: 'Grupo ACTO'
    },
    methods: {
        closeNavCollapse() {
            if (this.isCollapseOpen) {
                this.$root.$emit('toggle::collapse', 'nav-collapse')
            }
        },
        changeLocale(locale) {
            
                i18n.locale = locale;
            }
    },
    data() {
        return {
            /* isCollapseOpen: false, */
            bgColor: '#007EFF',
            position: 'top-right',
            fabActions: [{
                    name: 'cache',
                    icon: 'cached',
                    color: 'red'
                },
                {
                    name: 'alertMe',
                    icon: 'add_alert'
                }
            ],
                languages: [
                    { flag: 'us', language: 'en', title: 'English' },
                    { flag: 'es', language: 'es', title: 'Español' }
                ]
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat&family=Open+Sans&display=swap');

#app {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    /*background: linear-gradient(90deg, #001021 0%, #00264d 100%);*/
    background-color: #0A090D;
}

#cubo1 .particle0 {
    fill: #007EFF;
}

#cubo1 .particle1 {
    opacity: 0.2;
}

#cubo1 .particle2 {
    opacity: 0.3;
}

#cubo2 .particle0 {
    opacity: 0.2;
    fill: #007EFF;
}

#cubo2 .particle1 {
    opacity: 0.3;
    fill: #007EFF;
}

#cubo2 .particle2 {
    fill: #007EFF;
}

#cubo3 .particle0 {
    opacity: 0.2;
    fill: #007EFF;
}

#cubo3 .particle1 {
    opacity: 0.3;
    fill: #007EFF;
}

#cubo3 .particle2 {
    fill: #007EFF;
}

#cubo4 .particle0 {
    opacity: 0.2;
    fill: #007EFF;
}

#cubo4 .particle1 {
    opacity: 0.3;
    fill: #007EFF;
}

#cubo4 .particle2 {
    fill: #007EFF;
}

#cubo4 .particle3 {
    opacity: 0.5;
    fill: #007EFF;
}

#cubo4 .particle4 {
    opacity: 0.7;
    fill: #007EFF;
}

#cubo4 .particle5 {
    opacity: 0.4;
    fill: #007EFF;
}

#nav {
    padding: 0px;
}

#nav a.router-link-exact-active {
    color: #437fce;
}

#nav a .dropdown-item {
    font-weight: regular;
    color: #00264D;
}

.ffooter {
    z-index: 1030;
}
</style>
